import Home from "./pages/Home.js"
import './Styles.css'

function App() {
  return (
    <>
      <div className="container">
        <Home />
      </div>
    </>
  )
}

export default App